import actions from './actions';

const {
  DATE_RANGE_BEGIN,
  DATE_RANGE_SUCCESS,
  DATE_RANGE_ERR,
} = actions;





const initialState = {
data: [],
  loading: false,
  error: null,
};

const DateRangeReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case DATE_RANGE_BEGIN:
      return {
        ...initialState,
        loading: true,
      };
    case DATE_RANGE_SUCCESS:
      return {
        ...initialState,
        data,
        loading: false,
      };
    case DATE_RANGE_ERR:
      return {
        ...initialState,
        error: err,
        loading: false,
      };
    default:
      return state;
  }
};

export { DateRangeReducer };
