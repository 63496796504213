const actions = {
  DATE_RANGE_BEGIN: 'DATE_RANGE_BEGIN',
  DATE_RANGE_SUCCESS: 'DATE_RANGE_SUCCESS',
  DATE_RANGE_ERR: 'DATE_RANGE_ERR',

  DateRangeBegin: () => {
    return {
      type: actions.DATE_RANGE_BEGIN,
    };
  },

  DateRangeSuccess: (data) => {
    return {
      type: actions.DATE_RANGE_SUCCESS,
      data,
    };
  },

  DateRangeErr: (err) => {
    return {
      type: actions.DATE_RANGE_ERR,
      err,
    };
  },
};

export default actions;
