import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

//import withoutLoginLayout from '../../src/layout/withoutLoginLayout';
import withAdminLayout from '../../src/layout/withAdminLayout';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const SignUp = lazy(() => import('../container/profile/authentication/overview/Signup'));
const FbLogin = lazy(() => import('../container/profile/authentication/overview/FbSignIn'));
const FbSignUp = lazy(() => import('../container/profile/authentication/overview/FbSignup'));
const ForgotPass = lazy(() => import('../container/profile/authentication/overview/ForgotPassword'));
const VerifyUser = lazy(() => import('../container/profile/authentication/overview/VerifyUser'));
const EventCard = lazy(() => import('../container/profile/authentication/overview/EventCard'));
const LadingPage = lazy(() => import('../container/home/LadingPage'));
const Calendars = lazy(() => import('../container/Calendar'));
const Calendar = lazy(() => import('../container/calendar/Calendar'));
const Projects = lazy(() => import('../../src/routes/admin/projects'));//scheduling_app/client/

/*
function NotFound() {
  return <Redirect to="/" />;
}
*/

function FrontendRoutes() {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/forgotPassword" component={ForgotPass} />
        <Route exact path="/:id/:title" component={Calendar} />
        <Route exact path="/view-events" component={VerifyUser} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/" component={LadingPage} />
        <Route exact path="/fbRegister" component={FbSignUp} />
        <Route exact path="/fbSignIn" component={FbLogin} />
        {/* <Route path="/calendar" component={Calendars} /> */}
        <Route path="/calendar" component={Calendar} />
        {/* <Route exact path="*" component={NotFound} /> */}
      </Suspense>
    </Switch>
  );
}

//export default AuthLayout(FrontendRoutes);
//export default withoutLoginLayout(FrontendRoutes);
export default withAdminLayout(FrontendRoutes);
